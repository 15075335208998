import React, { Component } from "react";
import { navigate } from "gatsby";
import axios from "axios";
import Layout from "../components/layouts/main-layout";
import Container from "../components/container";
import Spinner from "../components/spinner";
import LessonResults from "../components/lesson-results";

const initialState = {
  lessons: [],
  formData: {
    sport: "ALL",
    type: "ALL",
  },
  formSent: false,
  isLoading: true,
};

class Anmeldung extends Component {
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(props.location.search);
    let sport = urlParams.get("sport");
    if (sport === null) sport = "ALL";
    let type = urlParams.get("type");
    if (type === null) type = "ALL";

    this.state = {
      ...initialState,
      formData: {
        ...initialState.formData,
        sport: sport,
        type: type,
      },
    };
    this.checkAvailability();
  }

  checkAvailability = () => {
    axios
      .get(`${process.env.GATSBY_TIDENS_API_URL}/lessons`, {
        params: {
          "filter[services.sport]": this.state.formData.sport,
          "filter[services.type]": this.state.formData.type,
        },
      })
      .then((res) => {
        this.setState({ lessons: res.data.data, isLoading: false });
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value,
      },
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    this.checkAvailability();
    navigate(
      `/kurstermine/?sport=${this.state.formData.sport}&type=${
        this.state.formData.type
      }`,
      { replace: true }
    );
  };

  render() {
    return (
      <Layout>
        <Container>
          <h1>Kurstermine</h1>
          <p>
            Moin, zurzeit befindet sich unsere Buchungsfunktion noch im Aufbau,
            daher sind <strong>noch nicht alle Kurse online</strong> zu finden. Falls du keinen
            passendes Kurs finden solltest oder Interesse an Aufbau- und
            Fortgeschrittenkursen sowie Privatstunden hast, melde dich bitte direkt bei uns.
          </p>
          <div className="mt-2">
            <form onSubmit={this.handleSubmit}>
              <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-4">
                <div>
                  <select
                    name="sport"
                    id="sport"
                    className="w-full px-4 py-3 rounded-[10px] focus:border-info bg-secondary focus:bg-white "
                    value={this.state.formData.sport || "ALL"}
                    onChange={this.handleChange}
                    required
                  >
                    <option value="ALL">Alle Sportarten</option>
                    <option value="KTS">Kitesurfen</option>
                    <option value="WAS">Wellenreiten</option>
                    <option value="WIS">Windsurfen</option>
                    <option value="SUP">SUP</option>
                    <option value="WIG">Wingsurfen</option>
                  </select>
                </div>
                <div>
                  <select
                    name="type"
                    id="type"
                    className="mt-4 lg:mt-0 w-full px-4 py-3 rounded-[10px] focus:border-info bg-secondary focus:bg-white "
                    value={this.state.formData.type || "ALL"}
                    onChange={this.handleChange}
                    required
                  >
                    <option value="ALL">Alle Kursarten</option>
                    <option value="TRY">Schnupperkurs</option>
                    <option value="BEG">Anfänger- / Grundkurs</option>
                    <option value="ADV">Aufbaukurs</option>
                    <option value="FRT">Fortgeschrittenenkurs</option>
                  </select>
                </div>
              </div>
              <div className="mt-3 flex flex-row justify-end">
                <button
                  type="submit"
                  className={`inline-flex items-center rounded-full px-5 py-2 mt-4 border-primary border-2 bg-primary text-white hover:font-bold ${
                    this.state.isLoading ? "cursor-not-allowed" : ""
                  }`}
                  disabled={this.state.isLoading}
                >
                  {this.state.isLoading === true && <Spinner />}
                  Suchen
                </button>
              </div>
            </form>
          </div>
          <div className="border-b border-primary flex flex-row justify-between mt-4">
            <div>
              <h3>Kurs</h3>
            </div>
            <div>
              <h3>Freie Plätze</h3>
            </div>
          </div>
          {this.state.isLoading === true ? (
            <div className="mt-6 flex flex-row justify-center">
              <Spinner />
            </div>
          ) : (
            <LessonResults lessons={this.state.lessons} />
          )}
        </Container>
      </Layout>
    );
  }
}

export default Anmeldung;
