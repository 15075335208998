
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const SportIcon = ({sport}) => {

  const iconWavesurf = (
    <StaticImage
      src="../images/icons/Wellenreiten.png"
      alt="Icon Wellenreiten"
      placeholder="blurred"
      objectFit="contain"
      quality={100}
      width={23}
      height={23}
      className="pr-2"    
    />
  );

  const iconWindsurf = (
    <StaticImage
      src="../images/icons/Windsurfen.png"
      alt="Icon Windsurfen"
      placeholder="blurred"
      objectFit="contain"
      quality={100}
      width={23}
      height={23}
      className="pr-2"    
    />
  );

  const iconSUP = (
    <StaticImage
      src="../images/icons/SUP.png"
      alt="Icon SUP"
      placeholder="blurred"
      objectFit="contain"
      quality={100}
      width={23}
      height={23}
      className="pr-2"    
    />
  );

  const iconWingsurf = (
    <StaticImage
      src="../images/icons/Wingsurfen.png"
      alt="Icon Wingsurfen"
      placeholder="blurred"
      objectFit="contain"
      quality={100}
      width={23}
      height={23}
      className="pr-2"    
    />
  );

  const iconKitesurf = (
    <StaticImage
      src="../images/icons/Kitesurfen.png"
      alt="Icon Kitesurfen"
      placeholder="blurred"
      objectFit="contain"
      quality={100}
      width={23}
      height={23}
      className="pr-2"
    />
  );

  switch (sport) {
    case "WAS":
      return iconWavesurf;
    case "WIS":
      return iconWindsurf;
    case "SUP":
      return iconSUP;
    case "WIG":
      return iconWingsurf;
    case "KTS":
      return iconKitesurf;
    default:
      return null;
  }

}
 
export default SportIcon;

SportIcon.defaultProps = {
  sport: "WAV"
};
