import React, { Component } from "react";
import Button from "./button";
import LessonResultDetails from "./lesson-result-details";

class LessonResults extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.lessons.length > 0) {
      return (
        <div>
          {this.props.lessons.map((lesson) => {
            return <LessonResultDetails lesson={lesson} key={lesson.hash} />;
          })}
        </div>
      );
    } else {
      return (
        <div className="mt-4 mb-6 text-lg">
          <p>Es wurden keine Online buchbaren Kurse gefunden.</p>
          <p>
            Bitte nimm per Telefon (
            <a href="tel:+4916099892384" className="text-info">
              +49 160 99892384
            </a>
            ) oder E-Mail (
            <a href="mailto:info@tidens-surfhuus.de" className="text-info">
              info@tidens-surfhuus.de
            </a>
            ) Kontakt mit uns auf, oder stelle uns eine Buchungsanfrage:
          </p>
          <Button
            external={true}
            to="https://docs.google.com/forms/d/e/1FAIpQLSedNmxlkNMQo-NWV1DMDF4Kn7f7eWHdyQMMD04xYPvszyJfWA/viewform"
            title="Buchungsanfrage"
            btntype="secondary"
          />
        </div>
      );
    }
  }
}

export default LessonResults;
