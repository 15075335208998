import React from "react";

const PlacesAvailablePill = ({ attendees, maxAttendees }) => {
  let pillClasses = "text-xl font-bold py-[6px] px-[10px] rounded-[10px]";

  if (attendees === maxAttendees) {
    pillClasses += " bg-availableredlight text-availablered";
  } else if (attendees / maxAttendees >= 0.5) {
    pillClasses += " bg-availableorangelight text-availableorange";
  } else {
    pillClasses += " bg-availablegreenlight text-availablegreen";
  }

  if (attendees == maxAttendees) {
    return <div className={pillClasses}>Voll</div>;
  } else {
    return (
      <div className={pillClasses}>
        {maxAttendees - attendees}
      </div>
    );
  }
};

export default PlacesAvailablePill;
