import React, { Component } from "react";
import axios from "axios";
import Button from "./button";
import SportIcon from "./sport-icon";
import PlacesAvailablePill from "./places-available-pill";
import Spinner from "../components/spinner";
import ConnectedLessons from "./lesson-result-connected-lessons";
import moment from "moment";

class LessonResultDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connectedLessons: {},
      isLoading: true,
    };

    this.getConnectedLessons();
  }

  getConnectedLessons = () => {
    axios
      .get(
        `${process.env.GATSBY_TIDENS_API_URL}/lessons/connected/${
          this.props.lesson.id
        }`
      )
      .then((res) => {
        this.setState({ connectedLessons: res.data.data, isLoading: false });
        console.log(this.state);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  sportDisplayName(sport) {
    let displayName = "";
    switch (sport) {
      case "KTS":
        displayName = "Kitesurfen";
        break;
      case "WAS":
        displayName = "Wellenreiten";
        break;
      case "WIS":
        displayName = "Windsurfen";
        break;
      case "SUP":
        displayName = "Stand Up Paddling";
        break;
      case "WIG":
        displayName = "Wingsurfen";
        break;
      default:
        displayName = "Unbekannt";
        break;
    }
    return displayName;
  }

  seatsAvailable(lesson) {
    if (lesson.attendees === lesson.max_attendees) {
      return false;
    } else {
      return true;
    }
  }

  timeframe(from, to) {
    let time_from = moment(from).format("HH:mm");
    let time_to = moment(to).format("HH:mm");
    return `${time_from} - ${time_to} `;
  }

  render() {
    return (
      <div className="border-b border-gray-400 pt-3 pb-3">
        {this.state.isLoading === true ? (
          <div className="mt-6 flex flex-row justify-center">
            <Spinner />
          </div>
        ) : (
          <div>
            <div className="flex flex-row justify-between">
              <div className="text-xl font-bold">
                <SportIcon sport={this.props.lesson.sport} />
                {this.sportDisplayName(this.props.lesson.sport)}
              </div>
              <div>
                <PlacesAvailablePill
                  attendees={this.props.lesson.attendees}
                  maxAttendees={this.props.lesson.max_attendees}
                />
              </div>
            </div>
            <div className="text-xl">{this.props.lesson.name}</div>
            <div className="text-info flex flex-row text-xl">
              {moment(this.props.lesson.planned_from).format("DD.MM.YYYY")}
              {this.state.connectedLessons.length > 0 ? (
                <div className="ml-1">
                  {"- " +
                    moment(
                      this.state.connectedLessons[
                        this.state.connectedLessons.length - 1
                      ].planned_to
                    ).format("DD.MM.YYYY")}
                </div>
              ) : (
                <div className="ml-1">
                  |{" "}
                  {this.timeframe(
                    this.props.lesson.planned_from,
                    this.props.lesson.planned_to
                  )}
                </div>
              )}
            </div>
            <div>
              {this.state.connectedLessons.length > 0 ? (
                <div><ConnectedLessons lesson={this.props.lesson} lessons={this.state.connectedLessons}/></div>
              ) : null}
            </div>
            <div className="flex flex-row justify-end">
              {this.seatsAvailable(this.props.lesson) === true ? (
                <Button
                  to={this.props.lesson.frontend_link_url}
                  btntype="secondary"
                  title="Online anmelden"
                  arrow={false}
                />
              ) : null}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default LessonResultDetails;
